var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('table', {
    attrs: {
      "border": "1"
    }
  }, [_vm._m(0), _vm.planInfo.planId ? _c('tr', [_vm._m(1), _c('td', {
    staticClass: "td-content"
  }, [_vm._v(_vm._s(_vm.planInfo.orderNo))])]) : _vm._e(), _vm.planInfo.planId ? _c('tr', [_vm._m(2), _c('td', {
    staticClass: "td-content time-cls"
  }, [_vm._v(_vm._s(_vm.planInfo.createTime))])]) : _vm._e(), _vm.planInfo.planId ? _c('tr', [_vm._m(3), _c('td', {
    staticClass: "td-content time-cls"
  }, [_vm._v(_vm._s(_vm.planInfo.dealTime))])]) : _vm._e(), _vm.planInfo.planId && _vm.planInfo.status > 1 ? _c('tr', [_vm._m(4), _c('td', {
    staticClass: "td-content"
  }, [_c('label', [_c('input', {
    staticClass: "device-type-cls",
    attrs: {
      "type": "radio",
      "value": "1",
      "disabled": ""
    },
    domProps: {
      "checked": _vm.planInfo.deviceType == 1
    }
  }), _vm._v(" PC商城")]), _c('label', [_c('input', {
    staticClass: "device-type-cls",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "type": "radio",
      "value": "2",
      "disabled": ""
    },
    domProps: {
      "checked": _vm.planInfo.deviceType == 2
    }
  }), _vm._v(" Android")]), _c('label', [_c('input', {
    staticClass: "device-type-cls",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "type": "radio",
      "value": "3",
      "disabled": ""
    },
    domProps: {
      "checked": _vm.planInfo.deviceType == 3
    }
  }), _vm._v(" IOS")]), _c('label', [_c('input', {
    staticClass: "device-type-cls",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "type": "radio",
      "value": "4",
      "disabled": ""
    },
    domProps: {
      "checked": _vm.planInfo.deviceType == 4
    }
  }), _vm._v(" 小程序")])])]) : _vm._e(), _c('tr', [_c('td', {
    staticClass: "td-title"
  }, [_vm._v("图片计划:")]), _c('td', {
    staticClass: "td-content"
  }, [_c('div', {
    staticClass: "img-content-cls"
  }, [_vm._l(_vm.planInfo.imageList, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "image-big-box"
    }, [_c('img', {
      staticStyle: {
        "width": "auto",
        "height": "auto",
        "max-width": "100%",
        "max-height": "100%"
      },
      attrs: {
        "src": item
      },
      on: {
        "click": function click($event) {
          return _vm.previewImg(i);
        }
      }
    }), _vm.planInfo.status === 1 ? _c('div', {
      staticClass: "delIcon",
      on: {
        "click": function click($event) {
          return _vm.delImg(i);
        }
      }
    }, [_vm._v("X")]) : _vm._e()]);
  }), (!_vm.planInfo.imageList || _vm.planInfo.imageList.length < 10) && (!_vm.planInfo.status || _vm.planInfo.status === 1) ? _c('div', {
    staticStyle: {
      "display": "inline-block",
      "margin": "5px 10px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "70px",
      "height": "70px"
    },
    attrs: {
      "src": require("../image/add.png")
    },
    on: {
      "click": _vm.clickImgUpload
    }
  }), _c('input', {
    ref: "imgUploadInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadImg($event);
      }
    }
  })]) : _vm._e()], 2)])]), _c('tr', [_c('td', {
    staticClass: "td-title"
  }, [_vm._v("表格计划:")]), _c('td', {
    staticClass: "td-content"
  }, [_vm._l(_vm.planInfo.fileList, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "file-big-box"
    }, [_c('div', {
      staticClass: "uploaded-file-display"
    }, [_c('div', {
      staticClass: "file-name",
      on: {
        "click": function click($event) {
          return _vm.previewFile(item.fileUrl);
        }
      }
    }, [_vm._v(_vm._s(item.fileName))]), _vm.planInfo.status === 1 ? _c('div', {
      staticClass: "del-operation",
      on: {
        "click": function click($event) {
          return _vm.delFile(i);
        }
      }
    }, [_vm._v("X")]) : _vm._e()])]);
  }), !_vm.planInfo.status || _vm.planInfo.status === 1 ? _c('div', {
    staticStyle: {
      "display": "inline-block",
      "margin": "5px 10px"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "70px",
      "height": "70px"
    },
    attrs: {
      "src": require("../image/add.png")
    },
    on: {
      "click": _vm.clickFileUpload
    }
  }), _c('input', {
    ref: "fileUploadInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": ".xlsx,.xls"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadFile($event);
      }
    }
  })]) : _vm._e()], 2)]), _c('tr', [_c('td', {
    staticClass: "td-title"
  }, [_vm._v("计划备注:")]), _c('td', {
    staticClass: "td-content"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.planInfo.remarks,
      expression: "planInfo.remarks"
    }],
    attrs: {
      "disabled": _vm.planInfo.status && _vm.planInfo.status > 1
    },
    domProps: {
      "value": _vm.planInfo.remarks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.planInfo, "remarks", $event.target.value);
      }
    }
  })])]), _vm.planInfo.planId ? _c('tr', [_c('td', {
    staticClass: "td-title"
  }, [_vm._v("处理结果:")]), _c('td', {
    staticClass: "td-content"
  }, [_vm._v(_vm._s(_vm.planInfo.dealResult))])]) : _vm._e()]), _c('div', [!_vm.planInfo.status || _vm.planInfo.status === 1 ? _c('div', {
    staticClass: "btn-cls",
    on: {
      "click": _vm.preSubmit
    }
  }, [_vm._v("提交")]) : _vm._e(), _c('router-link', {
    staticClass: "btn-cls",
    attrs: {
      "to": {
        name: 'planBookList'
      }
    }
  }, [_vm._v("返回")])], 1), _vm.previewImgShow ? _c('div', {
    staticClass: "msg-popup"
  }, [_c('div', {
    staticClass: "msg-popup-mask",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closePreviewImgShow.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "popup_content"
  }, [_c('img-preview', {
    attrs: {
      "previewImg": _vm.previewImgInfo
    },
    on: {
      "close": _vm.closePreviewImgShow
    }
  })], 1)]) : _vm._e(), _vm.previewFileShow ? _c('div', {
    staticClass: "msg-popup"
  }, [_c('div', {
    staticClass: "msg-popup-mask",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closePreviewFileShow.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "popup_content"
  }, [_c('div', {
    staticClass: "popup_close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closePreviewFileShow.apply(null, arguments);
      }
    }
  }, [_vm._v("X")]), _c('div', {
    staticClass: "popup_download_btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.downLoadPreviewFile.apply(null, arguments);
      }
    }
  }, [_vm._v("下载")]), _c('iframe', {
    attrs: {
      "src": _vm.previewFileUrl,
      "width": "100%",
      "height": "100%"
    }
  })])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tr', {
    staticClass: "detail-title"
  }, [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("计划本详情")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('td', {
    staticClass: "td-title"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v("计划本单号:")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('td', {
    staticClass: "td-title"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v("上报时间:")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('td', {
    staticClass: "td-title"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v("处理时间:")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('td', {
    staticClass: "td-title"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v("上报来源:")]);
}];
export { render, staticRenderFns };